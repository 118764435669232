// export const fadeIn = (element, display) => {
//   element.style.opacity = 0;
//   element.style.display = display || "block";

//   (function fade() {
//     var val = parseFloat(element.style.opacity);
//     if (! ((val += .1) > 1)) {
//       element.style.opacity = val;
//       requestAnimationFrame(fade);
//     }
//   })();
// };

// export const fadeOut = (element) => {
//   element.style.opacity = 1;

//   (function fade() {
//     if ((element.style.opacity -= .1) < 0) {
//       element.style.display = "none";
//     } else {
//       requestAnimationFrame(fade);
//     }
//   })();
// };

export const isSp = () => {
  return window.matchMedia("(max-width: 1023px)").matches;
};
export const isPcTb = () => {
  return isSp() === false;
};
export const setClosePosition = (element) => {
  if (isPcTb()) {
    element.style.transform = "translateX(-100%)";
  } else {
    element.style.transform = "translateX(100%)";
  }
};
export const setOpenPosition = (element) => {
  element.style.transform = "translateX(0px)";
};
