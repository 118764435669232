import ScrollHint from 'scroll-hint';
import { throttle } from "throttle-debounce";
import { isPcTb, isSp, setClosePosition, setOpenPosition } from './modules/util';
import Swiper from "swiper/bundle";
import InteructionModule from './modules/interuction';
import SwiperUtil from './modules/swiper-utils';

const body = document.querySelector('body');
const menuBtn = document.querySelector('#js-menu');
const menuPanel = document.querySelector('#js-gnav');
const mainMenu = document.querySelector('#js-gnav .global-nav__inner');
const subMenuBtn = document.querySelectorAll('.js-subMenu');
const contents = document.querySelectorAll("main,footer");

//メニュー初期設定:CSSでは非表示としておきJS起動時に追加する
setClosePosition(menuPanel);
let windowScrollPx = window.pageYOffset;

// Global menu
function globalMenuSP() {
  menuBtn.addEventListener("click", () => {
    if ("true" === menuBtn.getAttribute("aria-expanded")) {
      // menu close
      setClosePosition(menuPanel);
      // scroll visual keep
      setTimeout(() => {
        // main,footer transform reset
        contents.forEach((elm) => {
          elm.style.transform = "";
        });
        body.classList.remove("is-open");
        // scroll unlock
        body.style.position = null;
        body.style.overflow = null;
        menuPanel.style.display = null;
        window.scrollTo(0, windowScrollPx);
      }, 300);

      menuBtn.setAttribute("aria-expanded", "false");
      menuBtn.setAttribute("aria-label", "メニューを開く");
      menuPanel.setAttribute("aria-hidden", "true");

      // close submenu
      allSubMenuClose();
    } else {
      body.style.overflow = 'hidden';
      menuPanel.style.display = 'block';

      // scroll lock
      windowScrollPx = window.pageYOffset;
      body.style.position = "fixed";

      // main,footer transform reset
      contents.forEach((elm) => {
        elm.style.transform = "translateY(" + -windowScrollPx + "px)";
      });

      // menu open
      setOpenPosition(menuPanel);
      body.classList.add("is-open");
      menuBtn.setAttribute("aria-expanded", "true");
      menuBtn.setAttribute("aria-label", "メニューを閉じる");
      menuPanel.setAttribute("aria-hidden", "false");
    }
  });

  //global menu submenu
  subMenuBtn.forEach(function (button) {
    const subMenuPanel = button.nextElementSibling;
    const subMenuback = subMenuPanel.firstElementChild;

    // submenu open
    button.addEventListener("click", (e) => {
      if ( ! isPcTb()) {
        mainMenu.classList.add("is-open");
        mainMenu.classList.add("is-animation");
      }
      const currentSubMenuPanel = e.currentTarget.nextElementSibling;
      button.setAttribute("aria-expanded", "true");
      subMenuback.setAttribute("aria-expanded", "true");
      currentSubMenuPanel.setAttribute("aria-hidden", "false");
      currentSubMenuPanel.style.visibility = "visible";
    });

    // submenu close
    subMenuback.addEventListener("click", () => {
      if ( ! isPcTb()) {
        mainMenu.classList.remove("is-open");
      }
      subMenuClose(button, subMenuPanel, subMenuback);
    });
  });

  const subMenuClose = (button, subMenuPanel, subMenuback) => {
    button.setAttribute("aria-expanded", "false");
    subMenuback.setAttribute("aria-expanded", "false");
    subMenuPanel.setAttribute("aria-hidden", "true");
    if ( ! isPcTb()) {
      setTimeout(() => {
        subMenuPanel.style.visibility = "hidden";
      }, 500);
    }
  };

  const allSubMenuClose = () => {
    mainMenu.classList.remove("is-animation");
    mainMenu.classList.remove("is-open");
    subMenuBtn.forEach(function (button) {
      const subMenuPanel = button.nextElementSibling;
      const subMenuback = subMenuPanel.firstElementChild;
      subMenuClose(button, subMenuPanel, subMenuback);
    });
  };
}

// Smooth scroll
function smoothScroll() {
  const smoothScrollTriggers = document.querySelectorAll('a[href^="#"]');
  // const offset = header.clientHeight + 30;
  smoothScrollTriggers.forEach(function (smoothScrollTrigger) {
    smoothScrollTrigger.addEventListener('click', function (e) {
      const href = smoothScrollTrigger.getAttribute('href');
      const targetElement = (href === '#') ? document.getElementsByTagName('html')[ 0 ] : document.getElementById(href.replace('#', ''));
      if (targetElement) {
        e.preventDefault();
        e.stopPropagation();
        const targetPosition = window.pageYOffset + targetElement.getBoundingClientRect().top;
        window.scroll({
          top: targetPosition,
          behavior: 'smooth'
        });
      }
    });
  });
}

//add is-scroll class to body
let scrollPx = window.pageYOffset;
window.addEventListener('scroll',
  () => {
    scrollPx = window.pageYOffset;

    if (scrollPx > 50) {
      body.classList.add("is-sp-logo");
    } else {
      body.classList.remove("is-sp-logo");
    }

    if (scrollPx > 300) {
      body.classList.add('is-scroll');
    } else {
      body.classList.remove('is-scroll');
    }
  }, {
    passive: true
  }
);

// Button list
function buttonList() {
  const btn = document.querySelectorAll('.js-button');
  const bg = document.querySelectorAll('.js-button-bg');
  const btnParent = document.querySelector('.js-button-parent');
  const duration = 500;
  btn.forEach(function (button, index) {
    // on animation
    button.addEventListener('mouseenter', () => {
      button.classList.add('is-active');
      button.classList.add('is-transition');
      bg[ index ].classList.add('is-active');
      btnParent.classList.add('is-hover');

      //text color animation
      const texts = button.querySelectorAll('*');
      texts.forEach((text) => {
        text.style.transitionDuration = "0ms";
        text.style.color = "#FFF";
        setTimeout(() => {
          text.style.transitionDuration = null;
          text.style.color = null;
        }, duration);
      });
      setTimeout(() => {
        button.classList.remove('is-transition');
      }, duration);
    }, false);

    // off animation
    button.addEventListener('mouseleave', () => {
      button.classList.remove('is-active');
      btnParent.classList.remove('is-hover');
      bg[ index ].classList.remove("is-active");
    }, false);
  });
}

//swiper list recruit top
const initSwiperList = () => {
  const swiperList = new Swiper('.js-swiper-list', { // eslint-disable-line no-unused-vars
    slidesPerView: 1,
    spaceBetween: 30,
    // loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1266: {
        slidesPerView: 3,
        spaceBetween: 40
      },
    },
  });

  new SwiperUtil(swiperList);
};

const initSwiperListTop01 = () => {
  const swiperListTop01 = new Swiper('.js-swiper-list-top01', { // eslint-disable-line no-unused-vars
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
  });
};

const initSwiperListTop02 = () => {
  const swiperListTop02 = new Swiper('.js-swiper-list-top02', { // eslint-disable-line no-unused-vars
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
  });
};

const initSwiperListTop03 = () => {
  const swiperListTop03 = new Swiper('.js-swiper-list-top03', { // eslint-disable-line no-unused-vars
    slidesPerView: 1,
  });
};

// swiper list corporate top
const initSwiperListCorpMv = () => {
  const swiperListCorpTopMv = new Swiper(".js-swiper-list-corp-mv", { // eslint-disable-line no-unused-vars
    /**
     * 以下ズームアニメーションのために必要なパラメータ
     */
    init: false, //インスタンス作成時に初期化しない
    //loop=trueにすると、前後にスライドが複製されて3→1のCSS解除が正しく動作しないので使わないこと
    loop: false,

    /**
     * 以下スライダー挙動に必要なパラメータ
     */
    effect: "fade",
    speed: 3000,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    }
  });
  //1枚目のアニメーション
  swiperListCorpTopMv.on("autoplayStart", (swiper) => {
    swiper.slides[ swiper.activeIndex ].classList.add("animation");
  });
  //2枚目以降のアニメーション
  swiperListCorpTopMv.on("slideChangeTransitionStart", (swiper) => {
    swiper.slides[ swiper.activeIndex ].classList.add("animation");
  });
  //アニメーション解除
  swiperListCorpTopMv.on("slideChangeTransitionEnd", (swiper) => {
    swiper.slides[ swiper.previousIndex ].classList.remove("animation");
  });
  //全て設定してから初期化
  swiperListCorpTopMv.init();
};

function checkBreakPoint() {
  new ScrollHint('.js-scrollable');
  if (isSp()) {
    initSwiperListTop01();
    initSwiperListTop02();
    initSwiperListTop03();
  } else {
    buttonList();
  }
}

window.addEventListener(
  'resize',
  throttle(200, function () {
    checkBreakPoint();
  }),
  false
);
checkBreakPoint();
globalMenuSP();
smoothScroll();
initSwiperList();
initSwiperListCorpMv();

/**
 * 画面内アニメーション割り当て
 */
new InteructionModule([
  //main, common
  ".top-sec01",
  ".top-sec01__title",
  ".top-sec01__txt",

  ".top-sec02",
  ".top-sec02__title",
  ".top-sec02__txt",

  ".top-sec03__en",
  ".top-sec03__jp",
  "._corporate .top-sec03 .swiper-wrapper",

  ".top-sec04__inner",
  ".top-sec04__title",
  ".top-sec04__item",

  ".button-list__en",
  ".button-list__jp",
  ".button-list__txt",

  ".top-sec05__title",

  ".top-sec06__title",
  ".top-sec06__item",

  ".button-type02",
  ".news-list li",

  //sub
  ".top-mainvisual__title",
  ".top-mainvisual__txt",

  ".heading-type02",
  ".heading-type03",
  ".heading-type04",
  ".content-block__inner p",
  ".content-block__inner .button-group",
  ".content-block__inner ul",
  ".content-block__inner ol",
  ".gobject--map",

  ".media-block__media",
  ".media-block__media .media-block__media",
  ".media-block__media .media-block__text",
  ".button-group",
  ".item-list02__item",
  ".history-block01__item",
  ".swiper-list__item",

  ".top-title",
  ".top-sec02__body",
  ".top-swiper-list__item",
  ".top-swiper-list02__item",

  ".faq-list__item",
  ".step-list01__item"
]);
