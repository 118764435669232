import { throttle } from "throttle-debounce";
import { isPcTb } from "./util";

export default class SwiperUtil {
  swiper;
  prevWrapSelector = ".swiper-prev-area";
  nextWrapSelector = ".swiper-next-area";
  prevButtonSelector = ".swiper-button-prev";
  nextButtonSelector = ".swiper-button-next";
  hoverClass = "is-hover";
  prevHoverClass = "is-prev";
  nextHoverClass = "is-next";
  disabledClass = "is-disabled";

  constructor(swiper) {
    this.swiper = swiper;

    this.init();
  }

  init = () => {
    const slider = this.swiper.el;
    if ( ! slider || slider === ".js-swiper-list") {
      return;
    }

    const prevArea = slider.querySelector(this.prevWrapSelector);
    const nextArea = slider.querySelector(this.nextWrapSelector);
    const prevBtn = slider.querySelector(this.prevButtonSelector);
    const nextBtn = slider.querySelector(this.nextButtonSelector);

    if ( ! prevArea || ! nextArea) {
      return;
    }

    const updateSize = () => {
      if (isPcTb()) {
        const areaWidth = (window.innerWidth - Number(slider.clientWidth)) / 2;
        prevArea.style.width = areaWidth + "px";
        prevArea.style.left = -areaWidth + "px";
        prevArea.style.height = this.swiper.height + "px";

        nextArea.style.width = areaWidth + "px";
        nextArea.style.left = slider.clientWidth + "px";
        nextArea.style.height = this.swiper.height + "px";
      }
    };

    updateSize();
    window.addEventListener("resize", throttle(200, updateSize));

    const enter = (e) => {
      e.currentTarget.classList.add(this.hoverClass);
      if (e.currentTarget === prevArea) {
        slider.classList.add(this.prevHoverClass);
      } else {
        slider.classList.add(this.nextHoverClass);
      }
    };

    const leave = (e) => {
      e.currentTarget.classList.remove(this.hoverClass);
      slider.classList.remove(this.prevHoverClass);
      slider.classList.remove(this.nextHoverClass);
    };

    let btn = null;
    const move = (e) => {
      e.stopPropagation();
      e.preventDefault();
      e.stopImmediatePropagation();
      if (e.currentTarget === prevArea) {
        btn = prevBtn;
      } else {
        btn = nextBtn;
      }
      btn.style.left = e.offsetX - 50 + "px";
      btn.style.top = e.offsetY - 40 + "px";

      return false;
    };

    const cancel = (e) => {
      e.stopPropagation();
      e.preventDefault();
      e.stopImmediatePropagation();
    };

    prevArea.addEventListener("mouseenter", enter);
    prevArea.addEventListener("mousemove", move);
    prevArea.addEventListener("mouseleave", leave);
    prevBtn.addEventListener("mousemove", cancel);
    prevArea.addEventListener("click", () => {
      this.swiper.slidePrev();
    });

    nextArea.addEventListener("mouseenter", enter);
    nextArea.addEventListener("mousemove", move);
    nextBtn.addEventListener("mousemove", cancel);
    nextArea.addEventListener("mouseleave", leave);
    nextArea.addEventListener("click", () => {
      this.swiper.slideNext();
    });

    this.swiper.on("activeIndexChange", (swiper) => {
      if (swiper.isEnd) {
        nextArea.classList.add(this.disabledClass);
      } else {
        nextArea.classList.remove(this.disabledClass);
      }
      if (swiper.isBeginning) {
        prevArea.classList.add(this.disabledClass);
      } else {
        prevArea.classList.remove(this.disabledClass);
      }
    });
  };
}
