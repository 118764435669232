export default class InteructionModule {
  observer;

  constructor(elements) {
    this.observer = new IntersectionObserver(this.callback, {
      root: null, //viewport
      rootMargin: "0px",
      threshold: 0.5,
    });

    this.addElements(elements);
  }

  addElements = (selector) => {
    const elements = document.querySelectorAll(selector);
    elements.forEach((elm) => {
      elm.classList.add("is-animate");
      this.observer.observe(elm);
    });
  };

  callback = (entries) => {
    entries.forEach((entry) => {
      //   entry.boundingClientRect
      //   entry.intersectionRatio
      //   entry.intersectionRect
      //   entry.isIntersecting
      //   entry.rootBounds
      //   entry.target
      //   entry.time
      if (entry.isIntersecting) {
        entry.target.classList.add("is-animated");
      }
    });
  };
}
